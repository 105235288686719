import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeQiye, getQiye, delCookie } from '@/utils/auth'
let logoutHref =  `${ process.env.VUE_APP_LOGIN_API }/login_out.php?target_uri=${ location.href }`

const CancelToken = axios.CancelToken;
let requestQueue = [];//重复请求拦截队列
function handleRequest(config) {// 重复请求拦截调用
    let norepeat;//显式声明需要拦截重复请求
    // console.log(config)
    let params = strToJson(config);
    norepeat = params.norepeat;
    // console.log(`handleRequest ${config.url} norepeat:${norepeat}`)

    let pending=[];
    if (norepeat){
        pending = requestQueue.filter(item => item.url === config.url);
        if (pending.length) {
            // 实例化CancelToken时，对参数 c 进行立即调用，即可立即取消当前请求
            config.cancelToken = new CancelToken(c => {
                c(`拦截重复的请求`);
            });
        } else {
            // 如果请求不存在
            requestQueue.push({
                url: config.url
            });
        }
    }
    // console.log(`handleRequest ${JSON.stringify(requestQueue)}`)

}
function handleResponse(config) { // 更新重复请求拦截队列
    let norepeat;
    // console.log(config)
    let params = strToJson(config);
    norepeat = params.norepeat;
    // console.log(`handleResponse ${config.url} norepeat:${norepeat}`)

    let reqQueue = [];
    if (norepeat) {
        reqQueue = requestQueue.filter(item =>{
            return item.url != config.url?true:false
        });
        requestQueue = reqQueue;
    }
    // console.log(`handleResponse ${JSON.stringify(requestQueue)}`)
}

function strToJson(config) { //将config内data 或 params转成json返回
    let params={};
    if (config.method == 'get' && config.params) {
        params = typeof config.params == 'string' ? JSON.parse(config.params) : config.params;
    } else if (config.data) {
        params = typeof config.data == 'string' ? JSON.parse(config.data) : config.data;
    }
    return params
}

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
    timeout: 20000
});
// request interceptor
service.interceptors.request.use(
    config => {
        // console.log(config.data.norepeat)
        handleRequest(config)

        config.headers['X-ISAPI'] = 1
        if (getToken()) {
            config.headers['C-Cookie'] = getToken()
            config.headers['token'] = getToken()
        }
        if(getQiye()){
            config.headers['Authorization'] = 'Bearer ' + getQiye()
        }
        return config
    },
    error => {
        console.log(`error:${error}`) // for debug
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        // console.log(response)
        // console.log(`http_code:${response.status}`)
        handleResponse(response.config)

        const res = response.data;
        if(res.code===0){//自定义状态码code
            return res;
        } else {
            // console.log(`code!=0,msg:${res.msg},url:${response.config.url}`);
            let showErrorMsg = true//是否页面是否需要显示接口报错信息，显式指定false则不显示
            let params = strToJson(response.config);
            if (params.show_error_msg == false){
                showErrorMsg=false
                return res
            }else{
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(new Error(res.msg || 'Error'))
            }
        }
    },
    error => {
        // console.log(error)
        if (!error.response) return;
        handleResponse(error.response.config)

        let code = error.response.data&&error.response.data.code;
        let errMsg='网络错误，请稍后再试';
        code ? errMsg = error.response.data.msg:'';

        // console.log(`error code:${code},errMsg:${errMsg},url:${error.response.config.url}`)
        if (code == 301) {
            // alert(`error 301:${JSON.stringify(error.response)}`)
            delCookie('yiihuu_sso')
            delCookie('nick')
            removeQiye()
            delCookie('qiyeActive')
            location.href = logoutHref
        }else{
            Message({
                message: errMsg,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service