import Cookies from 'js-cookie'

const TokenKey = 'yiihuu_sso'
const HeadInfoKey = 'YiihuuHeaderInfo'
const NickKey = 'nick'
const EnterPrise = 'qiyeToken'
const QiyeActive = 'qiyeActive'
const SearchKey = 'searchCookie'
const AdHeader = 'head_ad_cookie'
const SignClose = 'signClose'
const VipLogin = 'vipLogin'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  Cookies.set(TokenKey, token)
}

export function removeToken() {
  Cookies.remove(TokenKey)
}

export function getHeaderInfo() {
  return Cookies.get(HeadInfoKey)? JSON.parse( Cookies.get(HeadInfoKey) ): ""
}

export function setHeaderInfo(data) {
  Cookies.set(HeadInfoKey, JSON.stringify( data ))
}


export function getQiye() {
  return Cookies.get(EnterPrise)
}

export function setQiye(token) {
  Cookies.set(EnterPrise, token)
}

export function removeQiye() {
  Cookies.remove(EnterPrise)
}

export function getActive() {
  return Cookies.get(QiyeActive)
}

export function getSignClose() {
  return Cookies.get(SignClose)
}

export function getVipLogin() {
  return Cookies.get(VipLogin)
}

export function setVipLogin(token) {
  Cookies.set(VipLogin, token)
}

export function removeVipLogin() {
  Cookies.remove(VipLogin)
}

export function getAdHeader() {
  return Cookies.get(AdHeader)
}

export function setAdHeader(active) {
  Cookies.set(AdHeader, active)
}

export function removeAdHeader() {
  Cookies.remove(AdHeader)
}

export function getNick() {
  return Cookies.get(NickKey) || ""
}

export function setNick(name) {
  Cookies.set(NickKey, name)
}

export function removeNick() {
  Cookies.remove(NickKey)
}

export function getCookie(name) {
  return Cookies.get(name)
}

export function setCookie(name, value) {
  Cookies.set(name, value, { domain: 'yiihuu.com' })
}

export function delCookie(name) {
  Cookies.remove(name, { domain: 'yiihuu.com' })
}

export function getSearch() {
  return localStorage.getItem(SearchKey) && JSON.parse(localStorage.getItem(SearchKey))
}

export function setSearch(value) {
  localStorage.setItem(SearchKey,JSON.stringify(value))
}

export function removeSearch() {
  localStorage.removeItem(SearchKey)
}



export function getMergeToken() {
  return localStorage.getItem("merge_token")||""
}
export function setMergeToken(value) {
  localStorage.setItem("merge_token",value)
}
export function removeMergeToken() {
  localStorage.removeItem("merge_token")
}

export function getMergeId() {
  return localStorage.getItem("merge_mid") || ""
}
export function setMergeId(value) {
  localStorage.setItem("merge_mid",value)
}
export function removeMergeId() {
  localStorage.removeItem("merge_mid")
}

// import Cookies from 'js-cookie'

// const TokenKey = 'yiihuu_sso'
// const NickKey = 'nick'

// const SearchKey = "searchCookie"

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   Cookies.remove(TokenKey)
// }

// export function getNick() {
//   return Cookies.get(NickKey) || ""
// }

// export function setNick(name) {
//  Cookies.set(NickKey, name)
// }
// export function removeNick() {
//   Cookies.remove(NickKey)
// }

// export function getSearch() {
//   return localStorage.getItem(SearchKey) && JSON.parse(localStorage.getItem(SearchKey))
// }

// export function setSearch(value) {
//   localStorage.setItem(SearchKey,JSON.stringify(value))
// }

// export function removeSearch() {
//   localStorage.removeItem(SearchKey)
// }