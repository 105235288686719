const EventBus = (Vue) => {
  Vue.prototype.$Bus = new Vue({
    methods: {
      on(event, ...orgs) {
        this.$on(event, ...orgs);
      },
      emit(event, callback) {
        this.$emit(event, callback);
      },
      off(event, callback) {
        this.$off(event, callback);
      }
    }
  });
}
export default EventBus;
