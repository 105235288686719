import { getToken, getNick } from '@/utils/auth'
import { getUser } from '@/api/common'
const state = {
  billInfo: {},
}

const mutations = {
  SET_BIllINFO: (state,info) => {
    state.billInfo = info;
  },
}

const actions = {
  setBillInfo({ commit,state,dispatch },data) {
    commit('SET_BIllINFO', data);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}