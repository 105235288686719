import { getToken, getNick } from '@/utils/auth'
import { getUser } from '@/api/common'
const state = {
  Token: getToken()? getToken() : '',
  Nick: getNick() ? getNick() : '',
  UserInfo:{},
  MemberInfo: {},
  Sign: 0,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.Token = token;
  },
  SET_USERINFO: (state, data) => {
    state.UserInfo = data;
  },
  SET_MEMBERINFO: (state, data) => {
    state.MemberInfo = data;
  },
  SET_MEMBERINFO: (state, data) => {
    state.MemberInfo = data;
  },
  SET_SIGN: (state, sign) => {
    state.Sign = sign;
  },
}

const actions = {
  getUserInfo({ commit, dispatch }) {
    /**获取用户信息 */
    return new Promise(async (resolve, reject) => {
      const res = await getUser().catch(err => { reject(err); });
      if(res){
        const { data } = res;
        const { member } = data;
        const { sign } = member;
        
        commit('SET_USERINFO', data);
        commit('SET_MEMBERINFO', member);
        commit('SET_SIGN', sign);
        resolve(res);
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}