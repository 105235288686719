import request from '@/utils/request'
import axios from 'axios'
import qs from 'qs'

export function header() {
  // 公共头部接口接入当前url所有查询参数如：推广参数tg，百度推广统计参数等、当前url地址
  let search=location.search,
      href=encodeURIComponent(location.href);
  let url
  if(search){
    url=`/api/header${search}&url=${href}`
  }else{
    url=`/api/header?url=${href}`
  }
  return request({
    url: url,
    method: 'get'
  })
}

// 获取用户信息
export function user() {
  return request({
    url: '/api/user',
    method: 'get'
  })
}


// 获取用户信息
export function getUser() {
  return request({
    url: '/api/user',
    method: 'get'
  })
}

// 记录点击调整下载APP次数
export function updateAppNum() {
  return axios.request({
    url: `${process.env.VUE_APP_HREF_API}/video/update_appnum.php`,
    method:'get',
    withCredentials: true,//允许跨域带Cookie
    params: data
  })
}

let HREF_API = process.env.VUE_APP_HREF_API
let LOGIN_API = process.env.VUE_APP_LOGIN_API
export function albumFavorite(aid_,type_) {//type_,1:收藏;2:取消收藏
    let unFav=false//'是取消收藏'标识
    if(type_==2){
        unFav=true
    }
    let param={
        m:'api',
        c:'common',
        a:'post_album_fav',
        id:aid_,
        show_error_msg:false
    }
    unFav ? param.a ='remove_album_fav':''

    return request({
        url: `${HREF_API}/default.php`,
        method: 'get',
        params: param
    })
}
// 大礼包上报
export function giftBag(data_) {
    /* 注意！！！ */
    /* 前后分离项目请求旧主站POST接口(php5.4)均有"接口获取不到POST参数值"问题 */
    /*
    vue发起的axios post请求，php5.4获取不到参数值，但php7 .2可以获取到post值
    后端处理方案：
    升级PHP版本或PHP 5.4 中添加一些代码来获取 POST 值
     $data = file_get_contents("php://input");
     $post = json_decode($data, true);

    前端处理方案：
    post参数使用qs.stringify 将对象转为form data url参数形式（不要使用封装的request方法，request.js handleRequest 有调用strToJson会使qs.stringify失效）
    */
    
    let data = qs.stringify(data_)
    return axios.request({
        url: `${HREF_API}/ajax/ajax_giftbag.php`,
        method:'post',
        withCredentials: true,//允许跨域带Cookie
        data: data
    })
}

// 领取大礼包
export function getGiftBagNew(data_) {
    return axios.request({
        // url: `${HREF_API}/giftbag/ajax/m_get_gift_bag_discount.php`,
        url: `${HREF_API}/giftbag/ajax/m_get_gift_bag_discount.php`,
        withCredentials: true,//允许跨域带Cookie
        method: 'get',
        params: data_
    })
}

export function ajaxIndexUserinfo(){
    return new Promise((resolve, reject) => {
        $.ajax({
            url: `${HREF_API}/ajax/ajax_index_userinfo.php`,
            type: 'post',
            dataType: 'jsonp',
            xhrFields: {
                withCredentials: true//设置withCredentials 允许跨域带Cookie
            },
            success: function (data) {
                resolve(data)
            },
            error: function (data) {
                reject(data)
            }
        });
    })
}

export function jsapi(data_) {
    let data = qs.stringify(data_)
    return axios.request({
        url: `${LOGIN_API}/js-api.php`,
        method: 'post',
        withCredentials: true,
        data: data
    })
}

export function bindMobile(data_) {
    let data = qs.stringify(data_)
    return axios.request({
        url: `${HREF_API}/center/ajax/bind_mobile.php`,
        method: 'post',
        withCredentials: true,
        data: data
    })
}
export function accountBind(data_) {
    return request({
        url: `${HREF_API}/center/ajax/account_bind.php`,
        method: 'get',
        params: data_
    })
}
export function setPass(data_) {
    let data = qs.stringify(data_)
    return axios.request({
        url: `${HREF_API}/center/ajax/set_pass.php`,
        method: 'post',
        withCredentials: true,
        data: data
    })
}

export function changeMobileBind(data_) {
    let data = qs.stringify(data_)
    return axios.request({
        url: `${HREF_API}/center/ajax/change_mobile_bind.php`,
        method: 'post',
        withCredentials: true,
        data: data
    })
}

// 合并账号
export function accountChange(data_) {
    return axios.request({
        url: `${HREF_API}/account_change.php`,
        method: 'get',
        withCredentials: true,
        params: data_
    })
}

// 兴趣弹窗
export function interestShow(data_) {
    return axios.request({
        url: `${HREF_API}/interest/ajax.php`,
        method: 'get',
        withCredentials: true,
        params: data_
    })
}
   


