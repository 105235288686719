import Vue from 'vue';
import md5 from 'js-md5';
import wx from 'weixin-js-sdk';
import layer from 'vue-layer';
import "vue-layer/lib/vue-layer.css";
import giftbag from '@/components/GiftBag/index.js'
import LoginDialog from '@/components/LoginDialog/index.js'
import MergePopup from '@/components/MergePopup/index.js'
import InterestDialog from '@/components/InterestDialog/index.js'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant)
Vue.prototype.$md5 = md5;
Vue.prototype.$wx = wx;
Vue.prototype.$layer = layer(Vue);

Vue.prototype.$giftbag = giftbag;
Vue.prototype.$login = LoginDialog;
Vue.prototype.$mergePopup = MergePopup;
Vue.prototype.$interestDialog = InterestDialog;
// export default Vue
