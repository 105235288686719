import Vue from "vue";
import Swiper from "swiper";
import qs from "qs";
import { WOW } from "wowjs"; //wow进场动画
import { wxShare } from "@/api/wechat/"; //微信分享接口
import wx from "weixin-js-sdk";
import { updateAppNum } from "@/api/common"

/**记录下载app点击*/
const $getUrl = async (where_type, browser_type) => {
  await updateAppNum({ op_type: 2, where_type: where_type,browser_type: browser_type, })
}
/** 跳转下载App页面*/ 
const $headDelay = (t, _type) => {
  try {
    window.location = "yiihuu://?openapp=1";
  } catch (err) {
    setTimeout(function () {
      window.location = "//m.yiihuu.com/download/app/?type=" + _type;
    }, t);
  } finally {
    setTimeout(function () {
      window.location = "//m.yiihuu.com/download/app/?type=" + _type;
    }, t);
  }
}
/** 环境判断 */
var $browser = (function () {
  var ua = navigator.userAgent;
  return {
      // 使用browser.version.QQ时，在QQ客户端和QQ浏览器中均返回true,所以无法判断
      // browser.version.QQbrw可以用来判断是否为QQ浏览器
      // 通过(browser.version.QQ && !browser.version.QQbrw)组合，就可以判断是否为QQ客户端
      isMobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/), //mobile
      isAndroid: !!/(Android|Linux)/i.test(ua), //Android
      isIos: !!/\i[^;]+;( U;)? CPU.+ Mac OS X/i.test(ua), //ios
      isIPhone: !!/iPhone/i.test(ua) || !!/Mac/i.test(ua), //'iphone' or 'QQHD brower'
      isIpad: !!/iPad/i.test(ua) || !!/Mac/i.test(ua), //ipad
      isWeibo: !!/WeiBo/i.test(ua), //microblog
      isWechat: !!/MicroMessenger/i.test(ua), //wechat
      isQQ: !!/\bQQ/i.test(ua), //QQ
      isQQbrw: ua.indexOf('QQBrowser') > -1,//QQ browser
      isFirefox: ua.indexOf('Gecko') > -1, //Firefox
      isUCbrw: !!/UCBrowser/i.test(ua), //UC brower
      isIE: !!/Trident/i.test(ua),//IE内核
      isOpera: !!/Presto/i.test(ua),//opera内核
      isWebkit: !!/AppleWebKit/i.test(ua),//safari & google内核
      isBaidu: !!/\bbaiduboxapp/i.test(ua), //baidu brower
      isApp: !!/isapp/i.test(ua)//app
  };
}());

// 定义公共变量
let $commonVariable = {
  timer: null,
  yihu_href_url: process.env.VUE_APP_HREF_API, //主站头部链接
};

// 公共方法
const $CommonFun = {
  // 禁止多个video标签同时播放
  prohibitVideo() {
    var au = document.getElementsByTagName("video");
    for (var i = 0; i < au.length; i++) {
      au[i].addEventListener("playing", function () {
        var this1 = this;
        this.play();
        for (var b = 0; b < au.length; b++) {
          var this2 = au[b];
          if (this1 !== this2) {
            this2.pause();
          }
        }
      });
    }
  },
  // 初始化swiper
  initSwiper(swiperIdentity, speed, loop) {
    new Swiper(swiperIdentity, {
      loop: loop,
      speed: speed,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  // wow进场动画
  initWow() {
    new WOW({
      animateClass: "animate__animated",
      offset: 100,
      // callback(box) {}
    }).init();
  },
  // 关闭微信二维码的定时器
  clearTimer() {
    clearInterval($commonVariable.timer);
    $commonVariable.timer = null;
  },
  // 兼容图片url
  handleImgUrl(imgUrl) {
    if (imgUrl) {
      if (imgUrl.indexOf("http") != -1) {
        return imgUrl;
      } else if (
        imgUrl.indexOf("teenmay/uploads") != -1 ||
        imgUrl.indexOf("teenmay/vueupload") != -1
      ) {
        return process.env.VUE_APP_COMMENT_IMG_URL + "/" + imgUrl;
      } else if (
        imgUrl.indexOf("xyls/uploads") != -1 ||
        imgUrl.indexOf("xyls/vueupload") != -1
      ) {
        return process.env.VUE_APP_COMMENT_IMG_URL + "/" + imgUrl;
      }
      return process.env.VUE_APP_OLD_IMG_UPLOAD_URL + "/" + imgUrl;
    }
  },
  // 兼容视频url
  handleVideoUrl(url) {
    if (url) {
      if (url.indexOf("http") != -1) {
        return url;
      } else if (url.indexOf("teenmay/") != -1) {
        return process.env.VUE_APP_COMMENT_VIDEO_URL + "/" + url;
      } else if (url.indexOf("xyls/") != -1) {
        return process.env.VUE_APP_COMMENT_VIDEO_URL + "/" + url;
      } else {
        let strix = "https://img.teenmay.cn/upimg/teenmay/";
        if (url) return strix + "/" + url;
      }
    }
    return "";
  },
  // ( 处理字符串 ) 如 a_11.html => 11  ; v_23.html => 23
  getUrlId() {
    let { pathname, search } = location;
    if (pathname) {
      let isExist = pathname.includes("_");
      if (isExist) {
        let str1 = pathname.split(".")[0];
        let str1Index = str1.indexOf("_") + 1;
        let id = str1.slice(str1Index);
        return id;
      }
    }
    if (search) {
      search = search.slice(1);
      let id = qs.parse(search).id;
      return id;
    }
    return "";
  },
  // 获取url 参数
  getUrlParams() {
    let { pathname, search } = location;
    let album_id = "";
    if (pathname) {
      let isExist = pathname.includes("_");
      if (isExist) {
        let str1 = pathname.split(".")[0];
        let str1Index = str1.indexOf("_") + 1;
        album_id = str1.slice(str1Index);
      }
    }
    if (search) {
      search = search.slice(1);
      let qsSearch = qs.parse(search);
      if (qsSearch.id) {
        album_id = qsSearch.id;
      }
      return { album_id, ...qsSearch };
    }
    return { album_id };
  },
  //检查设备系统
  checkDevice() {
    let u = navigator.userAgent,
      app = navigator.appVersion;
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1, //是否web应用程序
    };
  },
  //获取域名参数
  getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  //在学人数
  teachFun(num) {
    num = num.toString();
    let textNum = parseInt(num);
    if (textNum > 10000) {
      let $frontNum = num.slice(0, -4);
      let $rearNum = num.substr(num.length - 4, 1);
      let $finalNum = $frontNum + "." + $rearNum + "万";
      textNum = $finalNum;
    }
    return textNum;
  },
  nodeAdd() {
    let _53code = document.createElement("script");
    _53code.src =
      "https://tb.53kf.com/code/code/22ea615180027776f6cac3042544c98d0/1";
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(_53code, s);
  },
  //百度统计
  bdStatistics() {
    var _hmt = _hmt || [];
    let hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?f8c9810e4091567afddb63e0bc63703e";
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  },
  //微信朋友圈分享
  initWxShareConfig(obj) {
    let environment = navigator.userAgent.toLowerCase();
    if (environment.match(/MicroMessenger/i) == "micromessenger") {
      let data_ = {
        url: encodeURIComponent(location.href),
      };
      wxShare(data_)
        .then((res) => {
          if (res.msg == "Success") {
            let wxData = res.data;
            // 通过config接口注入权限验证配置
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: wxData.appId, // 必填，公众号的唯一标识
              timestamp: wxData.timestamp, // 必填，生成签名的时间戳
              nonceStr: wxData.nonceStr, // 必填，生成签名的随机串
              signature: wxData.signature, // 必填，签名
              jsApiList: [
                "updateTimelineShareData",
                "updateAppMessageShareData",
              ], // 必填，需要使用的JS接口列表
            });
            // 通过ready接口处理成功验证
            wx.ready(function () {
              let last_param = window.location.href.split("?")[0] + "?is_wx=1";
              console.log(last_param);
              wx.updateAppMessageShareData({
                title: obj.title, // 分享标题
                desc: obj.desc, // 分享描述
                link: last_param, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: obj.imgUrl, // 分享图标
                success: function () {
                  // 设置成功
                  console.log("QQ分享成功");
                },
              });
              wx.updateTimelineShareData({
                title: obj.title, // 分享标题
                // link: window.location.href.split('#')[0] + '#' + last_param, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                link: last_param, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                // link: 'http://m-test.teenmay.cn/work/13?is_wx=1', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: obj.imgUrl, // 分享图标
                success: function () {
                  // 设置成功
                },
              });
            });
            // 通过error接口处理失败验证
            wx.error(function (res) {
              console.log("微信分享失败：" + JSON.stringify(res));
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  getCourseUrl(id) {
    /**拼接课程 */
    return `/a_${id}.html`;
  },
  jumpLogin() {
    /**登录 */
    this.$layer.msg("请先登录");
    setTimeout(() => {
      window.location.href =
        process.env.VUE_APP_LOGIN_API +
        "/m_login.html?v=3&target_uri=" +
        location.href;
    }, 600);
  },

  async headOpenApp(where_type, _type) {
    /** 顶部导航栏打开App */
    if (!where_type) {
      where_type = 1;
    }
    if ($browser.isAndroid) {
      var browser_type = 1;
      $getUrl(where_type,browser_type);
      $headDelay(2500, _type);
    } else if ($browser.isIos) {
      var browser_type = 2;
      $getUrl(where_type,browser_type);
      if ($browser.isWebkit) {
        $headDelay (0, _type);
      } else {
        $headDelay (2500, _type);
      }
    }
  },
};

Vue.prototype.$commonVariable = $commonVariable;
Vue.prototype.$CommonFun = $CommonFun;
Vue.prototype.$browser = $browser;
Vue.prototype.$prefixImage = $CommonFun.handleImgUrl;
Vue.prototype.$prefixVideo = $CommonFun.handleVideoUrl;
Vue.prototype.$getUrlId = $CommonFun.getUrlId;
Vue.prototype.$getUrlParams = $CommonFun.getUrlParams;
Vue.prototype.$checkDevice = $CommonFun.checkDevice;
Vue.prototype.$getQueryString = $CommonFun.getQueryString;
Vue.prototype.$teachFun = $CommonFun.teachFun;
Vue.prototype.$nodeAdd = $CommonFun.nodeAdd;
Vue.prototype.$bdStatistics = $CommonFun.bdStatistics;
Vue.prototype.$initWxShareConfig = $CommonFun.initWxShareConfig;
Vue.prototype.$getCourseUrl = $CommonFun.getCourseUrl;
Vue.prototype.$jumpLogin = $CommonFun.jumpLogin;
Vue.prototype.$headOpenApp = $CommonFun.headOpenApp;

// 调用（引入百度统计代码）
$CommonFun.bdStatistics();
