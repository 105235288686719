<template>
<div id="giftPack_iframebox" v-if="visible">
    <div class="verify" >
        <div class="gift_bj"></div>
        <div class="novice_w1">
            <!-- 新手大礼包 -->
            <div class="novice_gift " :class="{ hide: step != 1 }">
                <div class="gift_after">
                    <p class="novice_title">新人专享首单8折</p>
                    <div class="gift_volume1">
                        <p>0元体验超级VIP特权</p>
                        <p>56+款软件  10000+VIP课时免费学</p>
                    </div>
                    <div class="gift_an">
                        <a href="javascript:void(0);" class="gift_Get"  @click="giftGet">立即领取</a>
                        <!-- <a href="javascript:void(0);" class="gift_login" onclick="parent.loginIframeLayer();">立即领取</a> -->
                    </div>
                </div>
            </div>

            <!-- 领取成功 -->
            <div class="receive_gift " :class="{ hide: step != 2 }">
                <p class="receive_title">恭喜你!</br>获得新人福利</p>
                <div class="gift_volume2">
                    <!-- <div class="text1">
                        <p><span>￥</span><span>600</span>礼券</p>
                        <p>可在“个人中心”—“优惠券“中查看</p>
                    </div> -->
                    <div class="text1">
                        <p>首单8折券</p>
                        <!-- <p>可在“个人中心”—“优惠券“中查看</p> -->
                        <p>全场通用(除特训营)</p>
                    </div>
                    <p class="text2">2小时超级VIP免费体验特权</p>
                </div>
                <div class="receive_all">
                    <a href="//m.yiihuu.com/vip/" class="skipVip" >立即体验VIP</a>
                    <!--<a href="javascript:void(0);" class="giftVip">免费加领15天VIP</a>-->
                </div>
            </div>
            <!--产品去掉体验15天vip 2023-05-08-->
            <!-- VIP加领15天（微信） -->
            <div class="plus_vip " :class="{ hide: step != 3 }">
                <!--<p class="plus_title">邀好友学习</br>免费领15天VIP</p>-->
                <div class="wx-ewm">
                    <img src="../../assets/giftbag/images/wx-ewm.png" alt=""/>
                </div>
                <p class="plus_tips">微信识别二维码，关注服务号领取</p>
            </div>

            <!-- 已领取 -->
            <div class="already_gift " :class="{ hide: step != 4 }">
                <div class="gift_after">
                    <p class="already_title">亲，您已领过大礼包哦!</p>
                    <!--<a href="javascript:void(0);" class="already_an giftVip">免费加领15天VIP</a>-->
                </div>
            </div>
        </div>
        <a href="javascript:void(0);" class="verify-close-btn" id="verify-closeBtn" @click="closeGiftBag"></a>
    </div>
</div>

</template>
<script>
import { giftBag, getGiftBagNew, commentIsLogin } from "@/api/common"

export default {
    data() {
        return {
            visible:false,
            show:true,
            step:1,
            layerFrom:1,
            qrcode: require('../../assets/giftbag/images/wx-ewm.png'),
            visible: false,
        };
    },
    created(){

    },
    mounted() {
        let HREF_API = process.env.VUE_APP_HREF_API
        let _gift_bag_click = this.getCookie('_gift_bag_click')
        //点击过领取新手大礼包，登录后刷新页面重新触发点击
        if (_gift_bag_click) {
            // console.log("_gift_bag_click","被触发")
            // let data = {
            //     show_error_msg: false
            // }
            // commentIsLogin(data).then(res => {
            //     console.log("commentIsLogin",res)
            //     if (res.error != '-1') { //登录
            //         this.giftGet()
            //     }
            // })
            // this.giftGet();
            this.setCookie('_gift_bag_click', "", -1);
        }
    },
    methods: {
        curStep(index_){
            return {
                hide: this.step != index_
            }
        },
        giftGet(){
            let pageSource = this.pageSource()
            this.statisticsClick(pageSource, 2)
            this.getGiftbag(pageSource)

        },
        async getGiftbag(flag){
            let data= {
                flag: flag,
                // show_error_msg: false
            }
            let res = await getGiftBagNew(data)
            res = res.data;
            if (res.code == 1) {
                this.step=2
                this.layerFrom=2
                //更换二维码
                this.qrcode= res.QRCode
            } else {
                if (res.code == -2) {
                    //已领
                    this.step = 4
                    this.qrcode = res.QRCode
                } else if (res.code == -3) {
                    this.$toast.center(res.msg);
                    this.$login.show();
                    //登录前立即领取，设置cookie,登录后刷新页面直接拉起新手大礼包iframe
                    this.setCookie('_gift_bag_click', 1);
                } else {
                    this.$toast.center(res.msg);
                }
            }
        },
        getSkipVip(){
            // 发起请求（体验VIP）
            let pageSource = this.pageSource()
            this.statisticsClick(pageSource, 4)
        },
        getGiftVip() {
            //点击加领vip打开微信二维码
            let pageSource = this.pageSource()
            this.statisticsClick(pageSource, 6)
            this.step=3
        },
        closeGiftBag(){
            let pageSource = this.pageSource()
            if(this.layerFrom==1){
                this.statisticsClick(pageSource, 5);
            } else if (this.layerFrom == 2) {
                this.statisticsClick(pageSource, 3);
            }
            this.setCookie('_iframe_giftPacks', 1);
            this.visible = false
        },
        // 统计展示&点击次数
        statisticsClick(pageFrom, step) {
            let data = {
                'option': 'tj',
                'page_from': pageFrom,
                'step': step,
                'platform': 1
            }
            giftBag(data)
        },
        pageSource() {// 判断页面来源
            var _pageSource = 0;
            //获取URL的路径部分
            var pathname = window.location.pathname;
            //截取一级路径名称
            var first_path = pathname.substr(1, pathname.indexOf('/', pathname.indexOf('/') + 1) - 1);
            if (pathname == '/' || pathname == '/home') { //首页
                _pageSource = 1;
            } else if (first_path == 'tutorials') { //大搜页
                _pageSource = 2;
            } else if (first_path == 'guide') { //软件入门
                _pageSource = 3;
            } else if (first_path == 'bc') { //大师课
                _pageSource = 4;
            } else if (first_path == 'vip') { //VIP专区
                _pageSource = 5;
            } else if (first_path == 'center') { //个人中心
                _pageSource = 6;
            } else if (first_path == 'soft_intro') { //宝典专题页
                _pageSource = 7;
            }else{
                _pageSource = 9;//新人大礼包活动落地页
            }
            return _pageSource;
        },
        setCookie(name, value) {//两个参数，一个是cookie的名子，一个是值
            var Days = 1; //此 cookie 将被保存 1 天
            var exp = new Date();    //new Date("December 31, 9998");
            exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
        },
        getCookie(name) {//取cookies函数
            var arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
            if (arr != null) return unescape(arr[2]);
            return null;
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/giftbag/css/m_giftbag_iframe.css'
</style>
