import Vue from 'vue'
import componentDialog from './index.vue'

let instance // 实例对象
let seed = 1 // 计数
let componentDialogConstructor = Vue.extend(componentDialog) // 构造函数
let componentDialogObj = {
  show(options = {}) {
    if (instance) {
      instance.vm.visible = true
      return instance.vm
    }
    let id = `loginDialog_${seed++}`
    instance = new componentDialogConstructor({
      data: options,
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style['z-index'] = 999 + seed
    return instance.vm
  },
  close() {
    if (instance) {
      instance.vm.visible = false
      // instance.vm.$destroy()
    }
  }
}

export default componentDialogObj