<template>
  <div class="" v-if="visible">
    <!-- 账号合并弹框 -->
    <div class="bind_window" id="bind_window" >
        <div class="bind_window_main">
            <div class="bwm_top">
                <h3>将此帐号与微信帐号合并</h3>
                <p>合并成功后，可查看所有的学习数据</p>
            </div>
            <div class="bwm_bottom">
                <a href="javascript:;" class="left" id="no_merge" @click="noMerge">暂不合并</a>
                <a href="javascript:;" class="right" id="merge_data" data-mid="" @click="mergeData">我要合并</a>
            </div>
        </div>
    </div>
    <!-- 账号合并成功 -->
    <div class="bind_success_bg">
        <div class="bind_success">
            <p style="color:#333;margin-top:1rem;margin-bottom:0.3rem;">合并成功</p>
            <div class="bind_true">
              <a href="//passport.yiihuu.com/login_out.php?target_uri=//m.yiihuu.com/include/wx_login_refresh.php" style="display:inline-block;width:100%;color:#fff;">确定</a>
            </div>
        </div>
    </div>
    <!-- 账号合并失败 -->
    <div class="bind_fail" id="fail_one">
        <p>合并失败</p>
    </div>
    <div class="bind_fail" id="fail_two">
        <p>账号类型不允许合并操作</p>
    </div>
  </div>
</template>

<script>
import { accountChange } from '@/api/common'
import { getMergeToken, getMergeId,removeMergeToken,removeMergeId } from '@/utils/auth.js'
export default {
  props: {},
  components: {},
  computed: {},
  data() {
    return {
      visible:false,
    };
  },
  created() {
    
  },
  methods: {
    async noMerge() {
      await accountChange({ 'action': 'no_merge' });
      this.visible = false;
    },
    async mergeData() {
      const merge_mid = getMergeId();
      const merge_token = getMergeToken();
      const res = await accountChange({ 'action': 'merge_data', 'merge_mid': merge_mid, 'merge_token': merge_token }) 
      const data = res.data;
      if (data.code == 1) {
        this.$toast.center("合并成功")
          
          // $(".bind_success_bg").show();
      } else if (data.code == -4) {
        this.$toast.center("账号类型不允许合并操作")
          // document.getElementById("bind_window").style.display = "none";
          // $("#fail_two").show();
          // setTimeout('$("#fail_two").fadeOut()', 2000);
      } else {
        this.$toast.center("合并失败")
          // document.getElementById("bind_window").style.display = "none";
          // $("#fail_one").show();
          // setTimeout('$("#fail_one").fadeOut()', 2000);
      }
      this.visible = false;
      removeMergeToken();
      removeMergeId();
      console.log("merge_mid",res);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(~@/assets/personal_center/css/bind_window.css);

</style>
