<template>
  <div class="module-container">
    <!-- var target_uri = url ? url : (show == 2 ? window.location.href+'?show='+show : window.location.href); -->
    <van-popup v-model="visible" position="bottom" closeable :style="{ height: '80%' }" >
        <iframe :src="iframeUrl()" frameborder="0" :width="iframeWidth" :height="iframeHeight"></iframe>
    </van-popup>
  </div>
</template>

<script>

export default {
  props: {
   
  },
  components: { },
  computed: {},
  data() {
    return {
      visible: false,
      iframeWidth:"0",
      iframeHeight: "",
    };
  },
  created() {
  },
  mounted() {
    document.domain = "yiihuu.com";
    this.iframeClient();
  },
  methods: {
    iframeUrl() {
      var target_uri = window.location.href;
      return `${process.env.VUE_APP_LOGIN_API}/m_login_new.html?target_uri=${target_uri}`
      // return `${process.env.VUE_APP_LOGIN_API}/m_login_account.html?target_uri=${target_uri}&source=1`
    },
    iframeClient() {
      var iframeWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var iframeHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.7;
      this.iframeWidth = iframeWidth+"px";
      this.iframeHeight = iframeHeight+"px";
    }
  },
};
</script>

<style lang="scss" scoped>
.van-popup{
  display: flex;
  align-items: flex-end;
}
</style>
