import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import HappyScroll from 'vue-happy-scroll';
import 'vue-happy-scroll/docs/happy-scroll.css';

import Toast from 'vue2-toast';
import 'vue2-toast/lib/toast.css';

import eventBus from '@/global/bus.js';

import '@/assets/css/normalize.min.css';
import '@/assets/css/minireset.min.css';
import '@/assets/font/iconfont.css';

import 'animate.css';
import 'amfe-flexible';
import Clipboard from 'v-clipboard';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Toast, { type: 'center',duration: 2000,});
Vue.use(eventBus);

Vue.use(HappyScroll);
Vue.use(ElementUI);

Vue.use(VueAwesomeSwiper);
Vue.use(Clipboard);

Vue.use(Vant);

